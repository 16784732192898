import { createApp/*, h*/} from 'vue';
import App from './App.vue';
import router from "./router";
import AOS from 'aos';
import 'aos/dist/aos.css';
import i18n from './i18n';
import store from "./state/store";




import BootstrapVueNext from 'bootstrap-vue-next';
import vClickOutside from "click-outside-vue3";
import VueApexCharts from "vue3-apexcharts";
import { vMaska } from "maska"

import VueFeather from 'vue-feather';

import '@/assets/scss/config/default/app.scss';
import "leaflet/dist/leaflet.css";
import '@/assets/scss/mermaid.min.css';
import 'bootstrap/dist/js/bootstrap.bundle'

import { fas } from '@fortawesome/free-solid-svg-icons'

import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import VueSelect from "vue-select";

/*import "xe-utils";
import VXETable from 'vxe-table'
import 'vxe-table/lib/style.css'*/

AOS.init({
    easing: 'ease-out-back',
    duration: 1000
});


/*VueSelect.props.components.default = () => ({
    Deselect: {
        render: () => h('span', '❌'),
    },
    OpenIndicator: {
        render: () => h('span', '🔻'),
    },
});*/


library.add(fas)

createApp(App)
    .use(store)
    .use(router)
    .use(VueApexCharts)
    //.use(VXETable)
    .use(BootstrapVueNext)
    
    .component(VueFeather.type, VueFeather)
    .component('font-awesome-icon', FontAwesomeIcon)
    .component("v-select", VueSelect)

    .directive("maska", vMaska)
    .use(i18n)
    .use(vClickOutside).mount('#app');