import axios from 'axios';
import router from '../router';
import store from "@/state/store";
import Cookies from 'js-cookie';
const instance = axios.create({
    baseURL: process.env.VUE_APP_API_DEV_URL, // 由環境變數設定網址
    headers: { 'Content-Type': 'application/json' },
    timeout: 20000
});


instance.interceptors.request.use(
    function (config) {
        return config;
    },
    function (error) {
        return Promise.reject(error);
    }
);


instance.interceptors.response.use(
    function (response) {
        return response;
    },
    function (error) {
        let error_message = '';
        console.log('axios response error.', error.message);
        if (error.message === 'Network Error') {
            return;
        } else if (error.message === 'Request aborted') {
            error_message = 'server error.';
            return;
        }

        if (error.response.status === 401) {
            error_message = 'token or session is invalid.';
            if (error.response.data.code == -1) {
                Cookies.remove('login');
                store.dispatch('webStore/ResetMenuList');
                console.log(router.currentRoute.value);
                router.push({ path: '/login'/*, query: { redirectFrom: router.currentRoute.value.fullPath } */});
                //window.location = '/logout';
            }

        } else if (error.response.status === 404) {
            error_message = 'page not found.';
        } else if (error.response.status === 500) {
            error_message = 'server error.';
        } else {
            error_message = error.message;
            console.log(error.message);
        }

        if (!window.navigator.onLine) {
            alert('網路連線失敗，請檢查您的網路連線。');
            return;
        }
        return Promise.reject({ status: error.response.status, body: error_message, errorcode: error.response.data.code });
    }
);

export default function (method, url, data = null, config) {
    method = method.toLowerCase();
    
    switch (method) {
        case 'post':
            return instance.post(url, data, config);
        case 'get':
            return instance.get(url, { params: data, headers: config.headers });
        case 'delete':
            return instance.delete(url, { params: data });
        case 'put':
            return instance.put(url, data);
        case 'patch':
            return instance.patch(url, data);
        default:
            console.log(`unknown method: ${method}`);
            return false;
    }
}

