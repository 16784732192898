//import Cookies from 'js-cookie';

export const state = {
    uinfo: {
        name: '',
        job_title: '',
        auth_id: -1,
        department_name:'',
    },
    menulist: '',
    page_auth: '',
};

const getDefaultState = () => {
    return {
        uinfo: {
            name: '',
            job_title: '',
            auth_id: -1,
            department_name: '',
        },
        menulist: '',
        //page_auth: '',
    }
}

export const mutations = {
    SET_USER_INFO(state, u) {
        state.uinfo = u;
    }, SET_PAGE_AUTH(state, p) {
        state.page_auth = p;
    }, SET_MENU_LIST(state, m) {
        state.menulist = m;
    }/*, RESET_ALL_STATE(state) {
        
    }*/
    , RESET_MENU_LIST(state) {
        Object.assign(state, getDefaultState())
    }
};

export const actions = {
    async SetUserInfo({ commit }, u ) {
        commit('SET_USER_INFO', u);
    },
    async SetMenuList({ commit }, m) {
        commit('SET_MENU_LIST', m);
    },
    async SetPageAuth({ commit }, m) {
        commit('SET_PAGE_AUTH', m);
    }/*,
    async resetState({ commit }) {
        commit('SET_MENU_LIST', m);
    }*/,
    async ResetMenuList({ commit }) {
        commit('RESET_MENU_LIST');
    },

};

export const getters = {
    getUserInfo: (state) => state.uinfo,
    getMenuList: (state) => state.menulist,
    getPageAuth: (state) => state.page_auth
};