import req from './axiosApi.js';
const qs = require('qs');
import md5 from 'js-md5';

export const userLogin = loginData => {
    const loginData_md5 = {
        accountid: loginData.AccountId,
        password: md5(loginData.Password),
    };

    return req('post', '/auth/signin', qs.stringify(loginData_md5), {
        headers: {
            "Content-Type": "application/x-www-form-urlencoded"
        }
    });
};

export const userMenu = () => {
    return req('get', '/auth/GetMenuList', '', {
        headers: {
            "Content-Type": "application/x-www-form-urlencoded"
        }
    });
};


export const userLogOut = () => {
    return req('post', '/auth/LogOut', '', {
        headers: {
            "Content-Type": "application/x-www-form-urlencoded"
        }
    });
};

export const getPageAuth = (path) => {
    return req('get', '/auth/CheckPermissions', '', {
        headers: {
            "x-target-path": path,
            "Content-Type": "application/x-www-form-urlencoded"
        }
    });
};

export const sendUserSectionAction = (data) => {
    return req('post', '/user/SendSectionAction', { uid: data.userid, sid: data.section_obj.sectionId}, {
        headers: {
            "Content-Type": "application/x-www-form-urlencoded"
        }
    });
};



export const getUserListByDepartmentId = function (data) {
    return req('get', '/user/GetUserListByDepartmentId', data, {
        headers: { "Content-Type": "application/x-www-form-urlencoded" }
    });
};

export const getUserListBySectionId = function (data) {
    return req('get', '/user/GetUserListBySectionId', data, {});
};