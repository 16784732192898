<template>
    <router-view></router-view>
</template>

<script>
    import appConfig from "/app.config";
    export default {
        name: 'App',
        components: {
        },
        mounted() {
            console.log('App.vue mounted');
        },
        methods: {
            CloseAllModal() {
                document.querySelectorAll(".btn-close").forEach(button => button.click());
            }, initAuth() {
                //page uath
                this.permissions = this.$store.getters['webStore/getPageAuth'];
            }, checkPermission(fnid) {
                if (fnid) {
                    return this.permissions.funlist.includes(fnid);
                } else {
                    return false;
                }
            },
            checkFileSize(bit) {
                return (bit / 1024 / 1024) > 15;
            }
        }, data() {
            return {
                permissions: {
                    read: false,
                    update: false,
                    add: false,
                    remove: false,
                    funlist:[],
                },
            }
        }, watch: {
            /*permissions: function (n, o) {
                console.log(n);
                console.log(o);
            }*/
            $route: {
                immediate: true,
                handler(to/*, from*/) {
                    if (to.meta.title) {
                        document.title = this.$t(to.meta.title) + ' | ' + appConfig.title;
                    }
                }
            }
        }

    }
</script>
<style>
    .tx-15 {
        font-size: 15px;
    }
    .tx-17{
        font-size:17px;
    }
    .tx-18 {
        font-size: 18px;
    }
    .tx-error {
        font-size: 15px;
        color:red;
    }
</style>
